angular.module('app')
    .factory('placeToAddressTransformer', ["$log", "$q", function ($log, $q) {

        var geocoder = new google.maps.Geocoder();

        function getAddressLine(address) {
            var addressLine = _.isUndefined(address.streetNumber) ? '' : address.streetNumber + ' ';
            addressLine += _.isUndefined(address.addressLine1) ? '' : address.addressLine1;
            return addressLine;
        }

        function getFormattedAddress(address) {
            var formattedAddress = _.isUndefined(address.buildingName) ? '' : address.buildingName + ', ';
            formattedAddress += getAddressLine(address) + ', ';
            formattedAddress += address.suburb;

            return formattedAddress;
        }

        function getResultByPlaceName(address, results) {
            for (var i = 0; i < results.length; i++) {
                if (results[i].formatted_address.indexOf(address.buildingName) > -1) {
                    return results[i];
                }
            }
            return results[0];
        }

        return {
            from: function (place, input) {
                var address;

                if (!place) return null;

                address = {};

                address.place = input.$viewValue;

                address.placeId = place.place_id;

                _.each(place.address_components, function (component) {
                    if (_.includes(component.types, 'street_number') && _.isUndefined(address.streetNumber)) {
                        address.streetNumber = component.long_name;
                    } else if (_.includes(component.types, 'route') && _.isUndefined(address.addressLine1)) {
                        address.addressLine1 = component.short_name;
                    } else if (_.includes(component.types, 'locality') && _.isUndefined(address.suburb)) {
                        address.suburb = component.long_name;
                    } else if (_.includes(component.types, 'administrative_area_level_1') && _.isUndefined(address.state)) {
                        address.state = component.short_name;
                    } else if (_.includes(component.types, 'postal_code') && _.isUndefined(address.postcode)) {
                        address.postcode = component.short_name;
                    }
                });


                if (_.isUndefined(address.addressLine1)) {
                    // place.name contains the human-readable name for the returned result. For establishment results, this is usually the canonicalized business name.
                    // if we don't have place.name we supply the closest available much as address line 1 is required by api.
                    address.addressLine1 = !_.isUndefined(place.name) ? place.name : place.address_components[0].short_name;
                }

                if (!_.isUndefined(place.name)) {
                    address.placeName = place.name;
                }

                address.latitude = (place) ? place.geometry.location.lat() : null;
                address.longitude = (place) ? place.geometry.location.lng() : null;

                return address;
            },
            to: function (address) {
                // Hand crank the address (note - this is only a fallback) ** not any more
                return {

                    place_id: address.placeId,
                    formatted_address: address.place,
                    name: address.placeName,
                    address_components: [
                        {
                            long_name: address.streetNumber,
                            short_name: address.streetNumber,
                            types: ['street_number']
                        },
                        {
                            long_name: address.addressLine1,
                            short_name: address.addressLine1,
                            types: ['route']
                        },
                        {
                            long_name: address.suburb,
                            short_name: address.suburb,
                            types: ['locality']
                        },
                        {
                            long_name: address.state,
                            short_name: address.state,
                            types: ['administrative_area_level_1']
                        },
                        {
                            long_name: address.postcode,
                            short_name: address.postcode,
                            types: ['postal_code']
                        }
                    ],
                    geometry: {
                        location: {
                            lat: function () {
                                return address.latitude;
                            },
                            lng: function () {
                                return address.longitude;
                            }
                        }
                    }
                };
            }
        };
    }]);
